import { LoadingContext } from "@/context/tools/loading"
import { cleanPath } from "@/utils/functions"
import IconsSVG from "@/utils/icons/IconsSVG"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useEffect, useState, memo, useContext } from "react"

export const DesktopMenu = memo(({
  icon,
  linkText,
  iconName,
  iconPath = ``,
  open,
  expandElem,
  handleChange,
  linkPath = ``,
  colors,
  colorScheme,
  host,
  nativeLogo = false,
  children,
  childs
}) => {
  const path = usePathname()
  const [ selected, setSelected ] = useState(false)
  useEffect(() => {
    setSelected(path === cleanPath(linkPath))
  }, [ path, linkPath ])
  const backgroundColor = colorScheme?.background ?? (selected || childs ? colors.lightGrey : colors.white)
  const textColor = selected || childs ? colors.tildMenu : colorScheme?.text ?? colors.color
  const url = linkPath.includes(`https`) ? cleanPath(linkPath) : host + cleanPath(linkPath)

  const { modules } = useContext(LoadingContext)
  return (
    <div className="home-menu">
      <div className="flex items-center " style={{ backgroundColor }}>
        <Link
          href={url}
          prefetch={false}
          onClick={async() => {
            await modules.handleLinkLoading(true)
            await handleChange(null, !expandElem)
          }}
          style={{
            textDecoration: `none`,
            width: icon ? `100%` : `100%`
          }}
          className="flex flex-row justify-between"
        >
          <div
            className="home-menu-elements w-[70%]"
            style={{
              "--hvr": colors.tildMenu,
              "--bg": backgroundColor,
              "--color": textColor
            }}

          >
            <IconsSVG
              name={iconName}
              path={iconPath}
              fillcolor={textColor}
              abbr={nativeLogo ? `native` : ``}
            />
            {open && (
              <p className=" px-2">
                {linkText}
              </p>
            )}

          </div>
          {icon && (
            <div
              className="w-10 float-end  flex cursor-pointer justify-end items-center px-3"
              style={{
              }}
              onClick={() => handleChange(null, !expandElem)}
            >
              {icon}
            </div>
          )}
        </Link>
      </div>
      {children}
    </div>
  )
})
